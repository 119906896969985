import { equals, has, is, not, pipe, reduce, values } from 'ramda'

/**
 * Whether the form was changed.
 *
 * @author Róbert Zentko
 * @version 1.0.0
 *
 * @param {Object} inputs - The form inputs.
 * @return {boolean}
 */
export const wasFormChanged = inputs => pipe(
    values,
    reduce((previous, current) => {
        if (! has('value', current)) {
            return previous && wasFormChanged(current)
        }
        
        const value = current?.value
        const oldValue = current?.oldValue
        
        return previous || (
            is(Array, value)
                ? not(equals(value, oldValue))
                : value !== oldValue
        )
    }, false)
)(inputs)