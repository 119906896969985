<template>
    <admin-layout>
        <visible-for role="admin">
            <h1 class="fw-bold text-center mb-4">
                Upraviť používateľa
            </h1>
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-xl-6">
                    <form @submit.prevent="submitForm">
                        <div class="row mb-3 g-3">
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <input id="first_name" v-model="input.first_name.value" :class="'form-control ' + isValidInput(input.first_name)" placeholder="" type="text">
                                    <label for="first_name">Meno *</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <input id="last_name" v-model="input.last_name.value" :class="'form-control ' + isValidInput(input.last_name)" placeholder="" type="text">
                                    <label for="last_name">Priezvisko *</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3 g-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input id="email" v-model="input.email.value" :class="'form-control ' + isValidInput(input.email)" placeholder="" type="email">
                                    <label for="email">E-mail *</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <select id="role_id" v-model="input.role_id.value" :class="'form-select ' + isValidInput(input.role_id)">
                                        <option v-for="role in userRoles" :value="role">{{ translate(role) }}</option>
                                    </select>
                                    <label for="role_id">Rola *</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-floating">
                                    <select id="language" v-model="input.language.value" :class="'form-select ' + isValidInput(input.language)">
                                        <option v-for="language in availableLocales" :value="language" :selected="language === 'sk'">{{ language.toUpperCase() }}</option>
                                    </select>
                                    <label for="language">Jazyk *</label>
                                </div>
                            </div>
                        </div>
                        <div class="packages row mb-4 g-3">
                            <div class="col-12">
                                <div class="row mb-2 g-2">
                                    <div class="col-8">
                                        <label class="fw-bold">Kurzy</label>
                                    </div>
                                    <div class="col-4">
                                        <label class="fw-bold">Expirácia</label>
                                    </div>
                                </div>
                                <div v-for="course in courses" class="row mb-2 g-2">
                                    <div class="col-8">
                                        <input :value="course.title" class="form-control" readonly style="border-color: #e9ecef" tabindex="-1">
                                    </div>
                                    <div class="col-4">
                                        <select id="packages" ref="packages" class="form-select" name="packages[]" @change="handleSelectChange">
                                            <option value="">-</option>
                                            <option v-for="pkg in course.packages" :selected="input.packages.value.includes(pkg.id)" :value="pkg.id">
                                                {{ translate(pkg.type) }} ({{ pkg.expire_in }} dní)
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <submit-button :disabled="! canSubmit || fetching" :fetching="fetching" title="Upraviť používateľa"></submit-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </visible-for>
    </admin-layout>
</template>

<script>
import AdminLayout from '../layout/AdminLayout'
import VisibleFor from '../component/VisibleFor'
import v8n from 'v8n'
import { validInput } from '@/util/validInput'
import { EMAIL_REGEX } from '@/util/regex'
import { forEachObjIndexed, includes, isEmpty, isNil, keys, last, map, pick, pipe, pluck, reduce, reject } from 'ramda'
import { roles } from '@/util/user'
import { translateKey } from '@/util/translateKey'
import { canSubmitForm } from '@/util/canSubmitForm'
import SubmitButton from '../component/SubmitButton'
import { formInput } from '@/util/formInput'
import { watchInputChange } from '@/util/watchInputChange'
import { prepareData } from '@/util/prepareData'
import { wasFormChanged } from '@/util/wasFormChanged'
import { api } from '@/store/api'
import { toast } from '@/util/toast'
import { availableLocales } from '@/locale'

export default {
    components: { SubmitButton, VisibleFor, AdminLayout },
    computed: {
        canSubmit () {
            return canSubmitForm(this.input) && wasFormChanged(this.input)
        },
        courses () {
            return this.$store.getters['course/selectCoursesByLanguage'](this.input.language.value)
            // return this.$store.getters['course/selectCourses']
        },
        fetching () {
            return this.$store.getters['user/isFetching']
        },
        packagesId () {
            return this.$store.getters['course/selectCoursePackagesId']
        },
        userId () {
            return this.$route.params?.userId
        },
        userRoles () {
            return roles
        },
    },
    created () {
        watchInputChange(this.input, this, true)
    },
    data () {
        return {
            availableLocales,
            input: {
                email: formInput({
                    validation: value => v8n()
                        .pattern(EMAIL_REGEX)
                        .test(value),
                }),
                first_name: formInput({
                    validation: value => v8n()
                        .string()
                        .not
                        .empty()
                        .test(value),
                }),
                language: formInput({
                    validation: value => v8n()
                        .string()
                        .not
                        .empty()
                        .test(value),
                }),
                last_name: formInput({
                    validation: value => v8n()
                        .string()
                        .not
                        .empty()
                        .test(value),
                }),
                packages: formInput({
                    value: [],
                    valid: true,
                    validation: value => (isEmpty(value) || reduce((prev, current) => prev && includes(current, this.packagesId), true, value)),
                }),
                role_id: formInput({
                    value: last(roles),
                    valid: true,
                    dirty: true,
                    validation: value => (includes(value, roles) && v8n()
                        .string()
                        .not
                        .empty()
                        .test(value)),
                }),
            },
            user: null,
        }
    },
    methods: {
        fillData (user) {
            if (isNil(user)) return
            
            const self = this
            const editableData = pick(keys(this.input), user)
            editableData.role_id = user.role?.id
            
            forEachObjIndexed(function (value, key) {
                const input = self.input[key]
                
                if (! input) return
                
                input.dirty = null
                input.value = input.oldValue = (key === 'packages' ? pluck('id', value) : value)
                input.valid = input.validation(value)
            }, editableData)
        },
        handleSelectChange () {
            this.input.packages.value = pipe(map(input => input?.value), reject(input => isNil(input) || isEmpty(input)))(this.$refs.packages)
        },
        isValidInput (input) {
            return validInput(input)
        },
        submitForm () {
            if (! this.canSubmit) return
            
            this.$store.dispatch('user/updateUser', {
                userId: this.userId,
                payload: prepareData(this.input),
            })
        },
        translate (key) {
            return translateKey(key)
        },
    },
    mounted () {
        if (! this.$route.params?.userId) {
            this.$router.push({ name: 'users.index' })
            
            return
        }
        
        api.user.get(this.$route.params?.userId)
           .then(response => {
               this.user = response?.data?.data
               
               this.fillData(this.user)
           })
           .catch(error => {
               toast.danger('Používateľa sa nepodarilo stiahnuť')
               console.error(error)
           })
    },
    name: 'EditUser',
    watch: {
        packagesId () {
            const input = this.input.packages
            
            input.valid = input.validation(input.value)
        },
        user (data) {
            this.fillData(data)
        },
    },
}
</script>

<style lang="scss" scoped>
.packages {
    label {
        text-align: left;
        white-space: normal;
        width: 70%;
    }
    
    select {
        text-align: right;
    }
}
</style>
